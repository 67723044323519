/* eslint-disable id-length */
export default {
    test: 'Hallo Test auf Deutsch',
    account: {
        test: 'Vítejte, {name}',
        subscribeButton: 'přihlásit se k odběru novinek',
        unSubscribeButton: 'Zrušit odběr novinek',
        unsubscribeSuccess: 'Úspěšně jste se odhlásili z odběru novinek.',
    },
    deal: {
        days: 'D',
        hours: 'H',
        minutes: 'M',
        seconds: 's',
        dealOver: 'Platnost nabídky vypršela.',
        countdownEnd: 'Tato nabídka končí',
    },
    mainNavigation: {
        greeting: 'Vítejte, {name}',
        account: 'Můj účet',
        logout: 'Odhlášení',
        search: 'Vyhledat produkt...',
        loggedout: 'Úspěšně jste se odhlásili!',
        searchSuggestion: 'Návrhy pro vyhledávání',
        productSuggestion: 'Návrhy produktů',
        showResults: '{count, plural, =1 {{text} Zobrazit výsledek} other {Zobrazit všechny {text} výsledků}}',
        resultSearch: '{count} {count, plural, one {Výsledek} other {Výsledků}} pro {query}',
        logoLinkLabel: 'Úvodní stránka',
        accountLinkLabel: 'Zákaznický účet',
        content: 'Obsah',
        burgerMenuButtonText: 'Menu',
    },
    product: {
        cheaperSet: '2. kus jen ',
        setTypeXplusY: '2. kus jen ',
        quantityAdvantage: 'Množstevní výhoda: ',
        quantityAdvantageActive: 'Množstevní sleva!',
        ratingCount: '{count} {count, plural, one {Recenze} other {Recenzi}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Cena včetně DPH',
        deliveryHint: 'Dodací a platební podmínky',
        savedMoneySuffix: 'Úspora',
        savedMoneyBadge: 'úspora',
        buyNow: 'Objednat teď',
        addToCart: 'Přidat do košíku',
        goToPdp: 'Na stránku s podrobnostmi o produktu',
        showDetails: 'Zobrazit podrobnosti',
        toProduct: 'k produktu',
        descriptionShowMore: 'Zobrazit více...',
        descriptionShowLess: 'Zobrazit méně...',
        detailsShowMore: 'Více informací',
        detailsShowLess: 'Méně informací',
        variantSelectorTitle: 'Prosím vyberte si:',
        emptyVariant: 'Prosím vyberte si',
        chooseVariant: ' vyberte',
        emptyVariantError: 'Prosím vyberte možnost',
        suggestedPriceShort: 'UVP',
        uvp: 'UVP',
        statt: 'namísto',
        variantSize: 'Velikost',
        variantColor: 'Barva',
        badges: {
            installments: '{count}x {count, plural, one {Splátka} other {Splátky\npo}} {price}',
            installmentsVariants: 'od {count}x {count, plural, one {Splátka\n} other {Splátky\npo}} {price}',
            installmentsVariantsAsAlternative:
                'alebo od {count}x {count, plural, one {Splátka} other {Splátky po}} {price}',
            installmentsAsAlternative: 'alebo {count}x {count, plural, one {Splátka} other {Splátky po}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {Splátka} other {Splátky po}}',
            installmentsInCart: '{count}x {count, plural, one {Splátka} other {Splátky po}} {price}',
            xplusygratis: '{text} GRATIS',
            savings: '{count}% úspora',
            savingsPDP: '-{count}%',
            deal: 'AKCE -{count}%',
            mobile: {
                gratisZubehoer: '+příslušenství',
            },
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'Podívej, co jsem našel:',
        attributes: {
            headline: 'Informace o produktu',
            subheadline: 'Technické údaje',
            true: 'Ano',
            false: 'Ne',
            maxOrderQuantity: 'Max. objednané množství',
            deliverables: 'Služby',
            name: 'Jméno',
            description: 'Popis',
            usp: 'USP',
            productDetails: 'Detaily produktu',
            claim: 'Nárok',
            weightGross: 'Hmotnost',
            articleType: 'Typ produktu',
            articleIsBuyable: 'Koupitelné',
            erpCategory1: 'Kategorie',
            erpCategory2: 'Podkategorie',
            crmArticleBundleNo: 'Artikelbündel Nr.',
            webSubtitle: 'Web podnadpis',
            countryLicensesAvailable: 'Dostupnost v zemi',
            articleDimensions: 'Dimenze',
            textilMaterialComposition: 'Materiál',
            relatedUpsell: 'Navýšení prodeje',
            relatedUpgrade: 'Příslušenství',
            subscriptionAvailable: 'Předplatné',
            subscriptionSelection: {
                day: '<b>Doba doručení</b>: {amount} {amount, plural, one {Den} other {Dnů}}',
                month: '<b>Doba doručení</b>: {amount} {amount, plural, one {Měsíc} other {Měsíce}}',
                week: '<b>Doba doručení</b>: {amount} {amount, plural, one {Týden} other {Týdny}}',
            },
            deliveryTimeFrom: 'Min. dodací lhůta',
            deliveryTimeUntil: 'Min. dodací lhůta',
            medicalProduct: 'Zdravotnická pomůcka',
            productChemicalIngredients: 'Složení',
            signalWord: 'Signalwort',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Ceny',
            biocidalProduct: 'Biocidní výrobky',
            shippingTimeCode: 'Kód času doručení',
            expiryDate: 'Datum platnosti',
            navBulkyItem: 'Nav Bulky Item',
            perDelivery: 'za dodávku',
        },
        shipping: {
            dropShipment: 'plus cena dopravy (Spedition): {price}',
            bulkyDelivery: 'plus cena dopravy (Sperrgut): {price}',
            totalCost: 'Spolu: {price}',
        },
        addedToCart: 'Dobrá volba! Produkt byl přidán.',
        cantAddToCart: 'Produkt nebylo možné přidat.',
        productNumber: 'Číslo produktu',
        productRelation: 'Související produkty',
        payOnce: 'nebo jednorázově',
        payOnceSum: 'Celková cena',
        payOnceSumMobile: 'Spolu',
        next: 'další',
        previous: 'předchozí',
        deliveryProductCard: 'Dodací lhůta: {from}-{until} pracovních dnů',
        downloadFor: 'Stáhnout manuál pro',
        topReview: 'Top rezenze',
        seeMore: 'Zobrazit více',
        seeLess: 'Zobrazit méně',
        totalReviews: '{formattedCount} {count, plural, one {recenze} other {recenzi}}',
        noReviews: 'Nejsou k dispozici žádné údaje.',
        videoThumbnailText: 'Video',
        subscription: {
            oneTimeDelivery: 'Jednorázová dodávka',
            abo: 'Úsporné předplatné',
            emptyOption: 'Interval doručení:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Den} other {Dnů}}',
                month: '{amount} {amount, plural, one {Měsíc} other {Měsíce}}',
                year: '{amount} {amount, plural, one {Rok} other {Roky}}',
            },
            delivery: 'doručení',
            recommendedInterval: 'doporučeno',
        },
        exceededMaxOrderQuantity: 'Maximální objednací množství ({amount}) pro tuto položku bylo překročeno.',
        pageTitle: '{title}{store, select, EMPTY {} other { | {store}}}',
        productUnavailable: {
            headline: 'Produkt není momentálně k dispozici!',
            text:
                'Není známo, zda a kdy bude tento produkt opět na skladě. ' +
                'Vyberte si místo toho alternativu z naší nabídky!',
        },
        bundleTitle: 'Dostupné i jako sada:',
    },
    preCheckout: {
        successMessage: 'Dobrá volba! Produkt byl přidán.',
        ctaMessage: 'přidat do košíku a',
        ctaMessageBold: 'Objednávka s dopravou zdarma!',
        goBackBtn: 'Pokračovat v nákupu',
        goToCartBtn: 'Zobrazit košík',
        showDetails: 'Detaily',
        addToCart: 'Objednat spolu',
        addToCartUpgrade: 'Upgrade',
        relations: 'Objednat také',
        toProduct: 'K produktu',
        deliverables: 'Rozsah doručení: ',
        clubSuggestion: 'Ušetřete navíc?',
        clubCardAlreadyInCart: 'V nákupním košíku již máte zákaznickou kartu.',
    },
    orderSuccessPage: {
        orderSuccess: 'Děkujeme za vaši objednávku!',
        goToShop: 'Zpět do obchodu',
        accountQuestion: 'Chcete nastavit heslo a využívat výhod zákaznického účtu?',
        accountAdvantagesHeader: 'Vaše výhody se zákaznickým účtem:',
        accountAdvantageOne: 'Přehled všech vašich objednávek',
        accountAdvantageTwo: 'Rychlejší dokončení objednávky',
        accountAdvantageThree: 'Správa údajů o zákaznících',
        password: 'Heslo',
        passwordReq: 'min. 8 znaků',
        passwordReqDescription: 'Zadejte bezpečnostní heslo.',
        passwordReqDescTitle: 'Vaše heslo musí obsahovat:',
        passwordReqDescOne: 'min. 8 znaků',
        passwordReqDescTwo: 'min. 1 číslo',
        passwordReqDescThree: 'min. 1 velké písmeno',
        passwordReqDescFour: 'min. 1 malé písmeno',
        checkboxText: 'Chci být informován o aktuálních nabídkách a slevách.',
        checkboxInfo: 'Z odběru se můžete kdykoli bezplatně odhlásit.',
        passwordSaveBtn: 'Uložit',
    },
    cart: {
        noReservation: 'Položky v nákupním košíku nebudou rezervovány',
        headline: 'Nákupní košík',
        articlesWithCount: '{count} produkt',
        addons: {
            headline: 'Objednat spolu',
            add: 'Objednat také',
        },
        subtotal: 'Mezisoučet',
        deliveryCost: 'Doprava a doručení',
        total: 'Spolu',
        allTotal: 'Spolu',
        shippedFreeOne: 'Při této objednávce máte dopravu ',
        shippedFreeTwo: 'ZDARMA',
        shippedFreeThree: '!',
        vatIncluded: 'včetně DPH',
        free: 'Zdarma',
        savings: 'Celkově ušetříte',
        savingsText: 'Celkově jste ušetřili',
        toCheckout: 'K pokladně',
        goSafeToCheckout: 'K POKLADNĚ',
        xArticle: ' ',
        voucher: 'Kupón',
        redeemVoucher: 'Zadejte kód kupónu',
        codePlaceholder: 'Kód kupónu (volitelné)',
        redeemCTA: 'Použít kupón',
        voucherCodeSuccess: 'Kód kupónu "{code}" byl uplatněn',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Kód kupónu "{code}" již byl uplatněn',
            nonApplicable: 'Tento kupón (již) není aktivní nebo jej nelze použít ve vašem aktuálním nákupním košíku!',
        },
        emptyCart: 'Váš nákupní košík je prázdný',
        emptyCartMessage: 'Vyberte si vaše oblíbené produkty a přidejte je do nákupního košíku.',
        emptyCartButton: 'Zpět do obchodu',
        emptyCartButtonMessage: 'Pokračovat v nákupu',
        voucherModal: {
            headline: 'Váš kupón byl úspěšně aktivován!',
            headlineGiftLineItem: 'Váš dárek zdarma byl aktivován!',
            text: '{totalSavings} sleva při minimální hodnotě objednávky {minValue}',
            continue: 'NAKUPOVAT',
        },
        wkoProductTitle: 'Váš dárek',
        wkoModalHeadline: 'Objednejte si zároveň vhodné příslušenství a pořiďte si dárek',
        wkoDeclineOffer: 'Ne, děkuji',
        wkoAddToCartSuccess: 'Dobrá volba!',
        restoreCartError: 'Váš nákupní košík se nepodařilo obnovit.',
        clubSavings: {
            clubSavingsText: 'Ušetřete dalších {savings} se zákaznickým klubem! ',
            clubSavingsLink: 'Klikněte {clubSavingsUnderline} pro podrobnosti!',
            clubSavingsUnderline: 'zde',
        },
        clubExtensionCta: 'Rozšířit',
        freeShippingBanner: {
            freeShipping: 'bezplatného doručení',
            headline: 'Už jen {onlyLeftPrice} zůstává do {freeShipping} při objednávce nad {fromPrice}',
            continue: 'Pokračovat v ',
            shopping: 'nákupu',
            order: 'Tato objednávka bude ',
            freeDelivery: 'doručena zdarma!',
        },
    },
    category: {
        showMore: 'Zobrazit více',
        breadcrumbHomeAriaLabel: 'Domovská stránka',
    },
    voucher: {
        codes: {
            notActive: 'Kupon není aktivní.',
            notValid: 'Kupon již není platný.',
            doesNotMatchCart: 'Kupon nesplňuje požadavky.',
            maxApplicationReached: 'Kupon již nelze uplatnit.',
            applicationStoppedByPreviousDiscount: 'Kupon nelze uplatnit z důvodu jiného kuponu.',
            matchesCart: 'Voucher je platný',
        },
        modal: {
            dontShowLegalText: 'Skrýt podmínky voucheru',
            displayLegalText: 'Zobrazit podmínky voucheru',
            savings: 'Sleva {amount}',
            headline: 'Který kupón si chcete ponechat?',
            minimalOrderAmount: ' při objednávce v hodnotě od {amount}',
        },
        priceGiftLineItem: 'Dárek zdarma: -{price}',
    },
    checkout: {
        minicartEdit: 'editovat',
        dividerText: 'nebo',
        allowRecurrence: {
            checkbox: 'Povolit opakované platby',
            error: 'Musíte souhlasit s opakovanými platbami',
        },
        catalogBillingAddressHeadline: 'Fakturační adresa',
        editBillingAddress: 'Upravit fakturační adresu',
        billingAddressHeadline: '1. Fakturační adresa',
        guestInfo:
            'Vaše údaje budou shromážděny pouze pro tuto objednávku ' +
            'a musí být poskytnuty znovu pro další objednávky. ' +
            'Abyste mohli využívat výhod zákaznického účtu, ' +
            'máte možnost si po dokončení objednávky vytvořit ' +
            'bezplatný zákaznický účet.',
        paymentHeadline: '2. Způsob platby',
        summaryHeadline: '3. Shrnutí',
        nextStep: 'Další',
        checkOrder: 'Zkontrolovat objednávku',
        nextStepPayment: 'Pokračujte k platebním metodám',
        lastStep: 'Krok zpět',
        buyNow: 'Objednat s povinností platby',
        cart: 'Nákupní košík',
        lineItem: {
            quantity: 'Množství',
        },
        freeDelivery: 'Při této objednávce máte poštovné ZDARMA!',
        enterAddress:
            'Nejprve prosím zadejte svou adresu.' +
            'Pak klikněte na "Pokračovat k platbě". ' +
            '\n\nV dalším kroku se zobrazí dostupné způsoby platby.',
        addresses: {
            headline: 'Údaje',
            customerLogin: 'Přihlášení k zákaznickému účtu',
            guestOrder: 'objednat jako host',
            register: 'Vytvoření zákaznického účtu',
        },
        payment: {
            paymentFee: 'Poplatky za transakci: {price}',
            paymentFeeForNachnahme: 'Poplatek za dobírku: {price}',
            noPaymentFee: 'Bez poplatků za bezhotovostní platbu',
            noPaymentFeeForNachnahme: 'Cena dobírky: 0 Kč',
            networkError: 'Bohužel momentálně nemůžeme nabízet nákup na účet. Vyberte jiný způsob platby.',
            tryOtherNetworkError: 'Bohužel momentálně nemůžeme nabízet nákup na účet. Vyberte jiný způsob platby.',
            installmentNetworkError:
                'Bohužel pro tuto objednávku nemůžeme nabídnout platby na splátky. ' +
                'Pro dokončení objednávky vyberte alternativní možnost platby.',
            invoiceNetworkError:
                'Při této objednávce bohužel nemůžeme nabídnout nákup na účet. ' +
                'Pro dokončení objednávky vyberte jinou možnost platby.',
            invoiceCheckDateOfBirth:
                'Údaje, které jste odeslali, nebylo možné úspěšně ověřit. ' +
                'Zkontrolujte prosím zadané údaje k datu narození a ujistěte se, ' +
                'že jsou správné a úplné.',
            invoiceAddressError:
                'Upozorňujeme, že při nákupu na účet se musí shodovat ' +
                'dodací a fakturační adresa pro účely identifikace. ' +
                'Vaše současné adresní údaje tento požadavek nesplňují, a proto není nákup na účet možný. ' +
                'Pro dokončení objednávky zvolte jinou možnost platby.',
            genericError:
                'Platba se nezdařila. Zkontrolujte fakturační a platební údaje a poté to zkuste ' +
                'znovu nebo zvolte jiný způsob platby.',
            unavailable: 'Způsob platby dočasně nedostupný',
            invoice: 'Kauf auf Rechnung',
            invoiceoffline: 'Kauf auf Rechnung',
            collectminvoice: 'Kauf auf Rechnung',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'Okamžitý převod',
            nachnahme: 'Dobírka',
            installment: 'Platba na splátky',
            installmentActivateCTA: 'Zkontrolovat nyní',
            installmentCheckbox:
                'Sie stimmen der Ratenzahlungsvereinbarung zu und können den' +
                'Ratenkredit-Vertragsentwurf {linkStart}her{linkEnd} herunterladen.',
            afterpayinvoice: 'AfterPay',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Poznámka',
            unavailableShippingCountry: 'Objednávku nelze odeslat na místo doručení, které jste vybrali.',
            descriptions: {
                postFinanceCard:
                    'Plaťte jednoduše a pohodlně inkasem z poštovního účtu. Po potvrzení nákupu zadejte ' +
                    'na platební obrazovce PostFinance číslo karty a ID karty PostFinance a potvrďte platbu.',
                visa: 'Bezpečná platba přes VISA Secure.',
                mastercard: 'Bezpečná platba přes Mastercard Identity Check.',
                payPal: 'Jednoduchá platba přes Váš PayPal účet. Na konci procesu objednávky budete přesměrováni přímo na PayPal. Vaše objednávka bude dokončena až po zpracování platby.',
                payPalPayLater:
                    '(Pokud chcete, můžete po přihlášení do služby PayPal zvolit také možnost „Zaplatit po 30 dnech“).',
                sofortueberweisung:
                    'Platba pomocí vašich online bankovních údajů. Po potvrzení nákupu budete přesměrováni přímo ' +
                    'na zabezpečený platební portál Sofort Klarna.',
                invoice:
                    'Nakupujte snadno na fakturu. Fakturu obdržíte spolu s dodávkou. Upozorňujeme, že při nákupu na účet se musí shodovat doručovací adresa a adresa na faktuře z důvodu identifikace. Tento způsob platby vyžaduje minimální věk 18 let a zahrnuje kontrolu úvěruschopnosti. Ujistěte se, že jste správně zadali datum narození.',
                invoiceOffline: 'Nakupujte snadno na fakturu. Fakturu obdržíte spolu s dodávkou.',
                installment:
                    'Plaťte bezpečně a flexibilně ve splátkách. Tento způsob platby vyžaduje minimální věk 18 let a zahrnuje kontrolu úvěruschopnosti. Ujistěte se, že jste správně zadali své adresní údaje a datum narození.',
                ideal: 'Platba pomocí vašich online bankovních údajů. Po potvrzení nákupu budete přesměrováni přímo na online portál vaší nizozemské banky, kde se můžete přihlásit svými přihlašovacími údaji do internetového bankovnictví a provést platbu.',
                afterPay:
                    'Zaplatit fakturu lze v několika krocích. Tento způsob platby zpracovává společnost AfterPay a vyžaduje minimální věk 18 let. Musíte správně zadat datum narození a telefonní číslo, aby společnost AfterPay mohla provést kontrolu totožnosti a úvěruschopnosti.',
                bancontact:
                    'Potřebujete kartu Bancontact od některé ze zúčastněných bank. Po potvrzení nákupu budete přesměrováni přímo na online portál Bancontact, kde můžete buď použít aplikaci Bancontact, nebo zadat údaje o své kartě Bancontact ručně a provést platbu.',
            },
        },
        shippingAddressHeadline: 'Dodací adresa',
        summary: {
            headline: 'Shrnutí',
            usps: {
                moneyBack: 'Záruka vrácení peněz',
                freeDelivery: 'bezplatné doručení',
                refund: '14 dní právo na vrácení zboží',
                freeReturn: 'bezplatné vrácení zboží',
                securePayment: 'Zabezpečená platba',
            },
            subtotal: 'Mezisoučet',
            deliveryCost: 'Poštovné',
            dropShippingCost: 'Cena dopravy (Spedition)',
            bulkyDeliveryCost: 'Cena dopravy (Sperrgut)',
            transactionFee: 'Bez poplatků za bezhotovostní platbu',
            transactionFeeCashOnDelivery: 'Poplatek za dobírku',
            total: 'Spolu',
            taxIncluded: 'včetně DPH',
            totalSavings: 'Celkově ušetříte',
            privacy: 'Vaše údaje používáme pouze v souladu s našimi {termsDetails}.',
            legal: 'Kliknutím na „Objednat s povinností platby“ souhlasíte se {termsDetails} společnosti {shopName}.',
            legalDetails: {
                privacyText: 'Zásadami ochrany osobních údajů',
                privacyLink: '/ochrana-osobnich-udaju',
                termsText: 'Všeobecnými obchodními podmínkami',
                termsLink: '/vop',
            },
            permissionEmailPhonePost:
                'Souhlasím se zpracováním svých údajů - jak je uvedeno v ' +
                '<link>zásadách ochrany osobních údajů</link> - pro účely informování o ' +
                'nových produktech a službách e-mailem, telefonicky nebo poštou. ' +
                'Tento souhlas mohu kdykoli odvolat.',
            permissionEmailPhonePostDetails: {
                link: '/ochrana-osobnich-udaju',
            },
            buyNow: 'Koupit teď',
            deliveryTime: 'Možnost dodání do {time} pracovních dnů',
            newsletterSubscription:
                'Chci bezplatně dostávat e-mailem {shopName} ' +
                'newsletter s nabídkami, výhodami a kupóny. Z odběru novinek ' +
                'se můžete kdykoli odhlásit prostřednictvím odkazu v e-mailech ' +
                'newsletteru.',
            comment: 'Zprávu doručovateli',
        },
        orderNotSavedYet: 'Vaše objednávka ještě nebyla uložena. Chcete stránku opravdu opustit?',
        resetPassword: {
            title: 'Poznámka',
            textBold: 'Už jste našim zákazníkem a měli jste již přístup do našeho starého internetového obchodu?',
            textNormal: 'Nyní nadále používejte {link} svůj stávající zákaznický účet.',
            textCta: 'resetovat předchozí heslo',
            resetButton: 'Resetovat heslo',
            closeButton: 'Zavřít',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Pozor',
                description: 'Opravdu se chcete vrátit do košíku?',
                dismiss: 'Zůstaňte v procesu platby',
                submit: 'Upravit nákupní košík',
            },
            summary: {
                amount: 'Množství: {amount}',
                billingAddress: 'Fakturační adresa',
                billingAndDelivery: 'Fakturace a doručení',
                change: 'změnit',
                lineItemTotalPrice: 'Celková cena: {price}',
                paymentType: 'Způsob platby',
                shippingAddress: 'Adresa dodání',
                title: 'Shrnutí',
                yourOrder: 'Vaše objednávka',
            },
            headlines: {
                headline: 'Objednejte bezpečně ve 3 krocích',
                address: 'Adresa',
                payment: 'Typ platby',
                summary: 'Zkontrolovat a odeslat',
            },
            nextButton: 'Další',
            checkDataButton: 'Kontrola údajů',
        },
        customerClubExtensionModal: {
            title: 'Attention',
            text:
                'Již jste členem zákaznického klubu. Věrnostní karta byla z vašeho nákupního košíku odstraněna. ' +
                'Chcete si místo toho zakoupit prodloužení svého stávajícího členství?',
            addToCart: 'Koupit obnovení',
            closeButton: 'Zavřít',
        },
        subscriptionInfo:
            'K zakoupení předplatného potřebujete zákaznický účet. Buď se přihlaste pomocí svého stávajícího ' +
            'zákaznického účtu, nebo si jednoduše vytvořte nový zákaznický účet.',
    },
    totalPrice: {
        taxIncluded: 'včetně DPH',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Fakturační adresa*',
        requiredField: '* označená pole jsou povinná',
        emailLabel: 'E-mailová adresa',
        emailPlaceholder: 'E-mailová adresa',
        emailErrorText: 'Prosím, zadejte platnou e-mailovou adresu.',
        salutationLabel: 'Oslovení*',
        salutationPlaceholder: 'Oslovení',
        salutations: {
            ms: 'Paní',
            mr: 'Pan',
            none: 'Neuvedeno',
        },
        customerTypeLabel: 'Typ zákazníka',
        customerType: {
            private: 'Soukromá osoba',
            corporate: 'Společnost',
        },
        contactLabel: 'Kontakt*',
        contactPerson: 'Kontaktní osoba',
        contactInfo: 'Kontaktní údaje',
        firstnamePlaceholder: 'Jméno',
        firstnameErrorText: 'Prosím, zadejte platné jméno.',
        lastnamePlaceholder: 'Příjmení',
        lastnameErrorText: 'Prosím, zadejte platné příjmení.',
        nameLengthErrorText: 'Povoleno je maximálně 200 znaků.',
        phonePlaceholder: 'Telefonní číslo',
        phoneErrorText: 'Prosím, zadejte platné telefonní číslo.',
        company: 'Společnost',
        companyPlaceholder: 'Společnost',
        companyErrorText: 'Zadejte platný název společnosti.',
        vatIdPlaceholder: 'Daňové číslo',
        vatIdErrorText: 'Zadejte platné daňové číslo.',
        address: 'Adresa',
        streetPlaceholder: 'Ulice',
        streetErrorText: 'Prosím, zadejte platnou ulici.',
        streetLengthErrorText: 'Povoleno je maximálně 100 znaků.',
        streetLengthErrorTextCZ: 'Povoleno je maximálně 40 znaků.',
        streetNumberPlaceholder: 'Č. domu',
        streetNumberErrorText: 'Prosím, zadejte platné číslo domu.',
        zipCodePlaceholder: 'PSČ',
        zipCodeErrorText: 'Prosím, zadejte platné PSČ.',
        cityPlaceholder: 'Město/obec',
        cityErrorText: 'Prosím, zadejte platné město/obec.',
        country: 'Země',
        countryLabel: 'Země',
        countryPlaceholder: 'Vyberte zemi',
        countyLabel: 'Okres',
        countyPlaceholder: 'Vyberte okres',
        packingStationPlaceholder: 'Doručení na odběrné místo*',
        packingStation: {
            yes: 'Ano',
            no: 'Ne',
        },
        shippingAddressHeadline: 'Dodací adresa:*',
        shippingAddressPlaceholder: 'To je také dodací adresa*',
        shippingAddress: {
            yes: 'Ano',
            no: 'Ne',
        },
        dayPlaceholder: 'Den',
        monthPlaceholder: 'Měsíc',
        yearPlaceholder: 'Rok',
        birthdateTitle: 'Datum narození',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'Zadejte platné datum.',
        birthdateTooYoungErrorText: 'Musíte mít alespoň 18 let.',
        birthdateDayErrorText: '*Neplatné',
        birthdateMonthErrorText: '*Neplatné',
        birthdateYearErrorText: '*Neplatné',
        birthdayTitle: 'Datum narození',
        birthDayError: 'Stáří nesmí překročit 100 let.',
        loqatePlaceholder: 'Zadejte adresu',
        loqateManualAddress: 'Zadejte adresu manuálně',
        loqateManualAddressHint: 'nebo pohodlně hledat podle adresy:',
        loqateSearchAddress: 'případně hledat podle adresy?',
        noResults: 'Omlouváme se, ale nebyly nalezeny žádné výsledky',
    },
    checkoutSuccess: {
        headline: 'Děkujeme za Vaši objednávku',
        headlineError: 'Vyskytla se chyba',
        textFirst: 'Přijali jsme Vaši objednávku a poslali jsme Vám také potvrzení na <link>{email}</link>.',
        textSecond: 'Jen co Vámi objednané zboží opustí náš sklad, budeme Vás opět informovat e-mailem.',
        textSecondPending:
            'Vaše objednávka se zpracovává. O vytvoření objednávky vás budeme informovat prostřednictvím e-mailu.',
        textError: 'Kontaktujte prosím zákaznický servis ohledně stavu Vaší objednávky.',
        noEmailPlaceholder: 'Vaše e-mailová adresa',
        newsletter: {
            headline: 'Zpravodaj',
            text: 'Chci být bezplatně informován o aktuálních nabídkách {shopName} prostřednictvím newsletteru.',
            feedbackText:
                'Úspěšná registrace newsletteru! Brzy obdržíte e-mail s odkazem na potvrzení ' +
                'své e-mailové adresy.',
            error: 'Přihlášení k odběru novinek není v současné době možné.',
        },
        catalog: {
            headline: 'Katalog',
            text: 'Chci dostávat katalog {shopName} zdarma a pohodlně poštou až domů.',
            feedbackText: 'Katalog vám bude brzy zaslán na vaši adresu.',
            error: 'Katalogová objednávka není v současné době možná.',
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'Změnit zemi',
        AT: 'Rakousko',
        DE: 'Německo',
        CH: 'Schweiz',
        LI: 'Lichtenštejnsko',
        HU: 'Maďarsko',
        IT: 'Itálie',
        BE: 'Belgie',
        NL: 'Nizozemí',
        BG: 'Bulharsko',
        HR: 'Chorvatsko',
        CY: 'Kypr',
        CZ: 'Česká republika',
        DK: 'Dánsko',
        EE: 'Estonsko',
        FI: 'Finsko',
        FR: 'Francie',
        GR: 'Řecko',
        IE: 'Irsko',
        LV: 'Lotyšsko',
        LT: 'Litva',
        LU: 'Lucembursko',
        MK: 'Makedonie',
        MT: 'Malta',
        PL: 'Polsko',
        PT: 'Portugalsko',
        RO: 'Rumunsko',
        SM: 'San Marino',
        SK: 'Slovensko',
        SI: 'Slovinsko',
        ES: 'Španělsko',
        SE: 'Švédsko',
    },
    socialMedia: {
        facebook: 'Sdílet na Facebooku',
        twitter: 'Sdílet na Twitteru',
        whatsapp: 'Sdílet přes Whatsapp',
        native: 'Sdílet',
    },
    productListing: {
        noProducts: 'Nebyly nalezeny žádné produkty',
        products: '{count} {count, plural, one {produkt} other {Produkty}}',
        loadMore: 'Načíst více',
        from: 'z',
        articles: 'články',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - Stránky {count}}}',
        sort: {
            bestseller: 'Popularita',
            price: {
                asc: 'Cena vzestupně',
                desc: 'Cena sestupně',
            },
            name: {
                asc: 'Název A-Z',
                desc: 'Název Z-A',
            },
        },
    },
    pagination: {
        ariaPage: 'Strana {pageNumber}',
        next: 'Dále',
        previous: 'Zpět',
    },
    menu: {
        logout: 'Odhlásit se',
        logoutHeader: 'Pozor',
        logoutText: 'Opravdu se chcete odhlásit?',
        logoutButtonText: 'Odhlásit se',
    },
    login: {
        password: 'Heslo',
        submitButtonLabel: 'Přihlaste se bezpečně',
        emailPlaceholder: 'Emailová adresa',
        passwordLabel: 'Zadejte heslo',
        usernameEmptyError: 'Uživatelské jméno by nemělo být prázdné',
        usernameEmailError: 'Uživatelské jméno by mělo být platnou e-mailovou adresou',
        userLoginError: 'Uživatelské jméno nebo heslo je nesprávné',
        passwordForgotten: 'Zapomněli jste heslo?',
        loginSuccess: 'Jste přihlášen',
        passwordEmptyError: 'Heslo by nemělo být prázdné',
        existingUser: 'Už jsem registrovaný zákazník, chci se přihlásit',
        resetPassword: 'Obnovit heslo',
        resetEmailSent: 'Poslali jsme vám e-mail na adresu {email}',
        resetErrorQuestions: 'Neobdrželi jste žádnou zprávu?',
        resetErrorAnswerOne: 'Zkontrolujte prosím složku se spamem ve své e-mailové schránce.',
        resetErrorAnswerTwo: 'Důkladně zkontrolujte svá data.',
        resetErrorAnswerThree: 'Před opětovným pokusem počkejte 15 minut.',
        newPassword: 'Nové heslo',
        passwordNotMatchingReq: 'Zdá se, že heslo neodpovídá požadavkům',
        passwordsNotIdentical: 'Nová hesla se neshodují',
        repeatPassword: 'Potvrdit heslo',
        changePassword: 'Změnit heslo',
        passwordChanged: 'Heslo bylo úspěšně změněno',
        emailConfirmed: 'E-mail byl potvrzen. Nyní se můžete přihlásit.',
        processToken: 'Při zpracování požadavku se vyskytla chyba. Zkuste později prosím.',
        generalError: 'Vyskytla se chyba. Zkuste později prosím.',
        authorizeStoreTitle: 'Autorizovat stávající pověření',
        authorizeStoreText: 'Chtěli byste povolit používání vašich stávajících pověření?',
        yes: 'Ano',
        no: 'Ne',
        emailUnconfirmed: 'Nejdříve potvrďte svou e-mailovou adresu.',
    },
    orders: {
        order: 'Objednat',
        total: 'Celkem',
        details: 'Prohlédněte si detaily',
        back: 'Zpět',
        shippingAddress: 'Dodací adresa',
        paymentMethod: 'Způsob platby',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Množství: {quantity}',
        reorder: 'Koupit znovu',
        reOrderSuccess: 'Položka byla úspěšně přidána',
        reOrderFail: 'Produkt se nepodařilo přidat do košíku',
        date: 'Datum',
        progress: '{items} ze {total} objednávky',
    },
    customer: {
        mr: 'Pan',
        mrs: 'Paní',
        customerNumber: 'Zákaznické číslo',
        billingAddress: 'Fakturační adresa',
        email: 'Emailová adresa',
        phone: 'Telefon',
        password: 'Heslo',
        passwordText: 'Heslo se z bezpečnostních důvodů nezobrazuje.',
        passwordLinkText: 'Zapomněl jsi heslo?',
        tokenNotReceived: 'Váš e-mail neexistuje v naší databázi',
        emailInvalid: 'Neplatná e-mailová adresa',
        dataUpdated: 'Údaje aktualizovány',
        wrongPassword: 'Heslo se nepodařilo změnit',
        couldNotResetPassword: 'Heslo nelze resetovat.',
        customerClub: {
            cardNumber: 'Číslo karty: {number}',
            validFrom: 'Členem od: {date}',
            validThru: 'Platí do: {date}',
            expiryWarning: 'Pozor: vaše karta ztratí platnost za {days} {days, plural, one {den} other {dní}}.',
            cardExpired: 'Pozor: Platnost vaší karty vypršela.',
            offerCardRenewal: 'Objednání nové karty',
        },
        customerCardLink: {
            headline: 'Propojte svůj online účet s vaší členskou kartou!',
            subHeadline: 'Využijte výhod zákaznického klubu při každém online nákupu!',
            cardNumber: 'Číslo karty',
            clubEmail: 'E-mailová adresa věrného zákazníka',
            cta: 'Propojiť',
            success: 'Vaše zákaznická klubová karta byla úspěšně propojena s vaším účtem.',
            error: {
                mykeneNotFound: 'Nepodařilo se najít žádnou zákaznickou klubovou kartu se zadaným číslem.',
                mykeneInvalidCombination: 'E-mailová adresa se neshoduje s klubovou kartou zákazníka.',
                mykeneError: 'Došlo k chybě. Zkuste to prosím později.',
                cardExistsForStore: 'Vaše zákaznická klubová karta již byla přidána k účtu.',
                unknownError: 'Došlo k chybě. Zkuste to prosím později.',
            },
        },
    },
    register: {
        passwordEmptyError: 'Heslo nemůže být prázdné',
        passwordMinError: 'Heslo musí mít alespoň 8 znaků',
        passwordNotValidError: 'Alespoň 1x číslo, 1x velké písmeno, 1x malé písmeno',
        passwordConfirmationError: 'Hesla se neshodují',
        passwordLabel: 'Vytvořit heslo',
        passwordConfirmationLabel: 'Opakovat heslo',
        passwordCriteriaHeader: 'Vaše heslo musí obsahovat ...',
        passwordCriteria: {
            numberOfCharacters: '✔ min. 8 znaků',
            hasNumber: '✔ min. 1 číslo',
            hasUppercaseLetter: '✔ min. 1 velké písmeno',
            hasLowercaseLetter: '✔ min. 1 malé písmeno',
        },
        acceptGDPRError: 'Musíte souhlasit se zásadami ochrany osobních údajů',
        nextStepPayment: 'Pokračovat k platbě',
        error: 'Promiňte, vyskytla se chyba. Prosím zkuste znovu.',
        successMessage: 'Registrace byla úspěšná. Potvrďte prosím svou e-mailovou adresu.',
        mainError: 'Prosím, opravte chyby!',
        change: 'ZMĚNIT',
        accountVerificationTitle: 'Ověření účtu',
        accountVerificationText:
            'Brzy dostanete e-mail s odkazem ' +
            'na aktivaci vašeho zákaznického účtu. Upozorňujeme, že tento odkaz je platný pouze 24 hodin. ' +
            'Neověřené zákaznické účty budou automaticky vymazány po 24 hodinách.',
        accountVerificationCTA: 'OK',
        existingAccountModal: {
            text:
                'Tato e-mailová adresa se již používá. Chcete-li se zaregistrovat v našem internetovém obchodě,' +
                ' přihlaste se pomocí uložených dat.',
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Přihlásit se',
            close: 'Zavřít',
        },
        newsletterSubscription: 'Chci být informován(a) o aktuálních nabídkách od {shopName}.',
        existingAccountToast: 'Tato e-mailová adresa se již používá.',
    },
    countrySwitcher: {
        headline: 'Změňte jazyk a nastavení dopravy',
        country: 'Země',
        language: 'Jazyk',
        button: 'Uložit',
        modal: {
            headline: 'Pozor!',
            description:
                'Měníte svůj region na {newSelectedCountry}. Abyste si mohli něco objednat,' +
                'vaše dodací adresa musí být v tomto regionu.',
            submitText: 'Další',
            closeText: 'Zrušit',
        },
    },
    personalInfo: {
        newEmail: 'Nová e-mailová adresa',
        newEmailConfirm: 'Potvrdit novou e-mailovou adresu',
        passwordConfirm: 'Potvrdit heslo',
        currentPassword: 'Aktuální heslo',
        newPassword: 'Nové heslo',
        newPasswordConfirm: 'Potvrdit nové heslo',
        actualEmail: 'Aktuální e-mailová adresa',
    },
    newsletter: {
        inputPlaceholder: 'E-mailová adresa',
        success: 'Brzy obdržíte e-mail s potvrzovacím odkazem.',
        invalidEmail: 'Zadejte platnou e-mailovou adresu.',
        error: 'Litujeme, vyskytl se problém s přihlášením k odběru novinek.',
        emailEmptyError: 'Prosím, zadejte e-mailovou adresu',
        registered: 'Přihlásili jste se k odběru novinek.',
        notRegistered: 'Nepřihlásili jste se k odběru novinek.',
        subscribe: 'Přihlásit se',
        unsubscribe: 'Odhlásit se',
        alreadySubscribed: 'E-mailová adresa je již přihlášena k odběru novinek.',
        successAccount: 'Úspěšně jste se přihlásili.',
        alreadyRegisteredQuestion: 'Už jste se zaregistrovali?',
        unsubscribeHere: 'Zde se můžete odhlásit',
        successUnsubscribe: 'Úspěšně jste se odhlásili',
        unsubscribeError: 'Došlo k chybě. Zkuste to prosím později.',
    },
    cartChangeModal: {
        headline: 'Pozor',
        description: 'Nákupní košík byl aktualizován, znovu vyberte způsob platby.',
        submitText: 'OK',
    },
    clubCustomer: {
        priceMessage: 'Zákaznická cena {price}',
        modal: {
            bannerHeadline: 'Staňte se naším stálým zákazníkem!',
            bannerSubHeadline: 'Čekají vás osobní slevy a poukázky!',
            headline: 'Proč je členství tak užitečné?',
            text:
                'Se získáním členské karty získáte 1-leté předplatné v našem ' +
                'Zákaznickém klubu, kde získáte spoustu výhod a pokaždé ušetříte ' +
                'více peněz! Během členství garantujeme také DOPRAVU ZDARMA na ' +
                'všechny Vaše objednávky. V našem klubu na Vás čeká několik ' +
                'dalších speciálních slev: *** nyní za každou novou registraci ' +
                'vracíme 1950 Kč ve formě kuponů! *** Kupony Vám budou zaslány ' +
                'poštou spolu s Vaší kartou, abyste si je mohli uplatnit již při ' +
                'další objednávce v našem internetovém obchodě nebo při ' +
                'telefonické objednávce na naší zákaznické lince. Poplatek ' +
                'za členskou kartu činí pouhých 799 Kč na jeden rok. Při ' +
                'nakupování se tedy vyplatí objednat si také členskou kartu! ' +
                'Kliknutím na tlačítko se Vaše členská karta přidá do nákupního ' +
                'košíku internetového obchodu, následně při dokončování objednávky ' +
                'uveďte své osobní údaje.',
            button: 'CHCI SE STÁT ČLENEM!',
        },
    },
    infoBanner: {
        closeButton: 'Zavřít',
    },
    slider: {
        prevButton: 'Zpět',
        nextButton: 'Dále',
        activePagination: 'aktivní rolování',
        inactivePagination: 'inaktivní rolování',
    },
    specialDeal: {
        days: '{count, plural, one {Den} other {Dny}}',
        hours: 'Hod.',
        minutes: 'Záp.',
        seconds: 'Sek.',
        specialDealOver: 'Bohužel, tato akce již skončila',
    },
    userManuals: {
        headline: 'Příručky',
        search: 'Hledat podle produktu, čísla výrobku nebo kódu',
        resetSearch: 'Obnovení vyhledávání',
        table: {
            sku: 'Číslo výrobku',
            code: 'Kód',
            productTitle: 'Produkt',
            type: 'Typ',
            types: {
                instruction_manual: 'Návod k obsluze',
                recipe_booklet: 'Brožura s recepty',
                training_plan: 'Plán školení',
                nutrition_plan: 'Výživový plán',
            },
        },
        noResults: 'Nebyly nalezeny žádné příručky.',
    },
    price: {
        from: 'od\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'namísto\u00a0{price}',
        uvp: 'UVP\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'Změna byla úspěšně provedena.',
        error: 'Došlo k chybě. Zkuste to prosím později.',
    },
    subscription: {
        amount: 'Množství:',
        deliveryInterval: 'Interval doručení:',
        nextDelivery: 'Další doručení:',
        ordered: 'Objednáno:',
        status: 'Postavení:',
        cancelSubscription: 'Zrušit předplatné',
        state: {
            active: 'Aktivní',
            paused: 'Pozastaveno',
            cancelled: 'Přestat',
            problems: 'Problémy',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Den} other {Dnů}}',
            month: '{amount} {amount, plural, one {Měsíc} other {Měsíce}}',
            year: '{amount} {amount, plural, one {Rok} other {Roky}}',
        },
    },
    routerErrorBoundary: {
        title: 'Nelze se připojit k serveru',
        infoText: 'Zkontrolujte prosím své internetové připojení nebo to zkuste znovu později.',
        buttonText: 'Znovu načíst stránku',
        stacktraceTitle: 'Podrobnosti',
    },
} satisfies IntlMessages;
