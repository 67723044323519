/* eslint-disable id-length */
export default {
    test: 'Hallo Test auf Deutsch',
    account: {
        test: 'Hallo {name} auf Deutsch im Account',
        subscribeButton: 'zum Newsletter anmelden',
        unSubscribeButton: 'Newsletter abmelden',
        unsubscribeSuccess: 'Du hast dich erfolgreich vom Newsletter abgemeldet.',
    },
    deal: {
        days: 'T',
        hours: 'S',
        minutes: 'M',
        seconds: 's',
        dealOver: 'Das Angebot ist abgelaufen.',
        countdownEnd: 'Dieses Angebot endet in',
    },
    mainNavigation: {
        greeting: 'Hallo, {name}',
        account: 'Mein Konto',
        logout: 'Abmelden',
        search: 'Produkt suchen...',
        loggedout: 'Abgemeldet!',
        searchSuggestion: 'Suchvorschläge',
        productSuggestion: 'Produktvorschläge',
        showResults: '{count, plural, =1 {{text} Produkt anzeigen} other {Alle {text} Produkte anzeigen}}',
        resultSearch: '{count} {count, plural, one {Ergebnis} other {Ergebnisse}} für {query}',
        logoLinkLabel: 'Startseite',
        accountLinkLabel: 'Kundenkonto',
        content: 'Inhalte',
        burgerMenuButtonText: 'Menü',
    },
    product: {
        cheaperSet: '2. Stück nur ',
        setTypeXplusY: '2. Set nur ',
        quantityAdvantage: 'Mengenvorteil: ',
        quantityAdvantageActive: 'Mengenvorteil aktiv!',
        ratingCount: '{count} {count, plural, one {Bewertung} other {Bewertungen}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Preis inklusive MwSt.',
        deliveryHint: 'Versand- und Zahlungsinformationen',
        savedMoneySuffix: ' Ersparnis',
        savedMoneyBadge: 'gespart',
        buyNow: 'Jetzt bestellen',
        addToCart: 'In den Warenkorb',
        goToPdp: 'Zur Produktdetailseite',
        showDetails: 'Variante wählen',
        toProduct: 'Zum Produkt',
        descriptionShowMore: 'Mehr anzeigen...',
        descriptionShowLess: 'Weniger anzeigen...',
        detailsShowMore: 'Mehr Details',
        detailsShowLess: 'Weniger Details',
        variantSelectorTitle: 'Bitte wählen:',
        emptyVariant: 'Bitte wählen',
        chooseVariant: ' wählen',
        emptyVariantError: 'Bitte Auswahl tätigen',
        suggestedPriceShort: 'UVP',
        uvp: 'UVP',
        statt: 'statt',
        variantSize: 'Größe',
        variantColor: 'Farbe',
        badges: {
            installments: '{count}x {count, plural, one {Rate} other {Raten\nje}} {price}',
            installmentsVariants: 'ab {count}x {count, plural, one {Rate\n} other {Raten\nje}} {price}',
            installmentsVariantsAsAlternative: 'oder ab {count}x {count, plural, one {Rate} other {Raten je}} {price}',
            installmentsAsAlternative: 'oder {count}x {count, plural, one {Rate} other {Raten je}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {Rate} other {Raten je}}',
            installmentsInCart: '{count}x {count, plural, one {Rate} other {Raten je}} {price}',
            xplusygratis: '{text} GRATIS',
            savings: '{count}% gespart',
            savingsPDP: '-{count}%',
            deal: 'AKTION -{count}%',
            mobile: {
                gratisZubehoer: '+Zubehör',
            },
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'Schau was ich gefunden habe:',
        attributes: {
            headline: 'Produktinformationen',
            subheadline: 'Technische Daten',
            true: 'Ja',
            false: 'Nein',
            maxOrderQuantity: 'Max. Bestellmenge',
            deliverables: 'Leistungen',
            name: 'Name',
            description: 'Beschreibung',
            usp: 'USP',
            productDetails: 'Produktdetails',
            claim: 'Claim',
            weightGross: 'Gewicht',
            articleType: 'Artikeltyp',
            articleIsBuyable: 'Kaufbar',
            erpCategory1: 'Kategorie',
            erpCategory2: 'Unterkategorie',
            crmArticleBundleNo: 'Artikelbündel Nr.',
            webSubtitle: 'Web Untertitel',
            countryLicensesAvailable: 'Länderverfügbarkeit',
            articleDimensions: 'Abmessungen',
            textilMaterialComposition: 'Material',
            relatedUpsell: 'Upsell',
            relatedUpgrade: 'Zubehör',
            subscriptionAvailable: 'Abonnement',
            subscriptionSelection: {
                day: '<b>Lieferintervall</b>: {amount} {amount, plural, one {Tag} other {Tage}}',
                month: '<b>Lieferintervall</b>: {amount} {amount, plural, one {Monat} other {Monate}}',
                week: '<b>Lieferintervall</b>: {amount} {amount, plural, one {Woche} other {Wochen}}',
            },
            deliveryTimeFrom: 'Min. Lieferzeit',
            deliveryTimeUntil: 'Max. Lieferzeit',
            medicalProduct: 'Medizinprodukt',
            productChemicalIngredients: 'Inhaltsstoffe',
            signalWord: 'Signalwort',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Preise',
            biocidalProduct: 'Biozidprodukte',
            shippingTimeCode: 'Lieferzeit Code',
            expiryDate: 'Ablaufdatum',
            navBulkyItem: 'Nav Bulky Item',
            perDelivery: 'pro Lieferung',
        },
        shipping: {
            dropShipment: 'zzgl. Liefergebühr (Spedition): {price}',
            bulkyDelivery: 'zzgl. Liefergebühr (Sperrgut): {price}',
            totalCost: 'Gesamtbetrag: {price}',
        },
        addedToCart: 'Gute Wahl! Der Artikel wurde hinzugefügt.',
        cantAddToCart: 'Artikel konnte nicht hinzugefügt werden.',
        productNumber: 'Art.Nr.',
        productRelation: 'Dazu passende Produkte',
        payOnce: 'oder einmalig',
        payOnceSum: 'Gesamtpreis',
        payOnceSumMobile: 'Gesamt',
        next: 'nächste',
        previous: 'früher',
        deliveryProductCard: 'Lieferzeit: {from}-{until} Werktage',
        downloadFor: 'Handbuch herunterladen für',
        topReview: 'Top Bewertung',
        seeMore: 'Mehr lesen',
        seeLess: 'Weniger anzeigen',
        totalReviews: '{formattedCount} {count, plural, one {Bewertung} other {Bewertungen}}',
        noReviews: 'Keine Daten vorhanden.',
        videoThumbnailText: 'Video',
        subscription: {
            oneTimeDelivery: 'Einmalige Lieferung',
            abo: 'Spar-Abo',
            emptyOption: 'Lieferintervall:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Tag} other {Tage}}',
                month: '{amount} {amount, plural, one {Monat} other {Monate}}',
                year: '{amount} {amount, plural, one {Jahr} other {Jahre}}',
            },
            delivery: 'Lieferung',
            recommendedInterval: 'empfohlen',
        },
        exceededMaxOrderQuantity: 'Maximale Bestellmenge ({amount}) für diesen Artikel überschritten.',
        pageTitle: '{title}{store, select, EMPTY {} other { | {store}}}',
        productUnavailable: {
            headline: 'Produkt derzeit nicht verfügbar!',
            text: 'Wähle stattdessen eine Alternative aus unserem Sortiment!',
        },
        bundleTitle: 'Auch als Set erhältlich:',
    },
    preCheckout: {
        successMessage: 'Gute Wahl! Der Artikel wurde hinzugefügt.',
        ctaMessage: 'zum Warenkorb hinzufügen und',
        ctaMessageBold: 'versandkostenfrei bestellen!',
        goBackBtn: 'Weitershoppen',
        goToCartBtn: 'Zum Warenkorb',
        showDetails: 'Details',
        addToCart: 'Mitbestellen',
        addToCartUpgrade: 'Upgrade',
        relations: 'Gleich mitbestellen',
        toProduct: 'zum Produkt',
        deliverables: 'Lieferumfang: ',
        clubSuggestion: 'Extra sparen?',
        clubCardAlreadyInCart: 'Du hast bereits eine Kundenkarte im Warenkorb.',
    },
    orderSuccessPage: {
        orderSuccess: 'Vielen Dank für deine Bestellung!',
        goToShop: 'Zurück zum Shop',
        accountQuestion: `Möchtest du noch schnell ein Passwort festlegen und von den Kundenkonto-Vorteilen profitieren?`,
        accountAdvantagesHeader: 'Deine Vorteile mit einem Kundenkonto:',
        accountAdvantageOne: 'Übersicht über alle deine Bestellungen',
        accountAdvantageTwo: 'Schnellerer Bestellabschluss',
        accountAdvantageThree: 'Verwaltung deiner Kundendaten',
        password: 'Passwort',
        passwordReq: 'mind. 8 Zeichen',
        passwordReqDescription: 'Bitte gib ein Passwort ein, um deine Informationen zu schützen.',
        passwordReqDescTitle: 'Dein Passwort muss:',
        passwordReqDescOne: 'mindestens 8 Zeichen lang sein',
        passwordReqDescTwo: 'mind. 1 Ziffer enthalten',
        passwordReqDescThree: 'mind. 1 Großbuchstaben enthalten',
        passwordReqDescFour: 'mind. 1 Kleinbuchstaben enthalten',
        checkboxText: 'Ich möchte über aktuelle Angebote von {shopName} informiert werden.',
        checkboxInfo: 'Eine Abmeldung ist jederzeit kostenlos möglich.',
        passwordSaveBtn: 'Speichern',
    },
    cart: {
        noReservation: 'Artikel im Warenkorb werden nicht reserviert',
        headline: 'Warenkorb',
        articlesWithCount: '{count} Artikel',
        addons: {
            headline: 'Gleich mitbestellen',
            add: 'Mitbestellen',
        },
        subtotal: 'Zwischensumme',
        deliveryCost: 'Versand & Lieferung',
        total: 'Summe',
        allTotal: 'Gesamtbetrag',
        shippedFreeOne: 'Diese Bestellung wird ',
        shippedFreeTwo: 'GRATIS',
        shippedFreeThree: ' versandt!',
        vatIncluded: 'inkl. MwSt.',
        free: 'Gratis',
        savings: 'Gesamte Ersparnis',
        savingsText: 'Du sparst insgesamt ',
        toCheckout: 'Zur Kasse',
        goSafeToCheckout: 'Zur Kasse',
        xArticle: '({amount} Artikel)',
        voucher: 'Gutschein',
        redeemVoucher: 'Gutschein einlösen',
        codePlaceholder: 'Gutscheincode (optional)',
        redeemCTA: 'Einlösen',
        voucherCodeSuccess: 'Gutscheincode "{code}" eingelöst',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Gutscheincode "{code}" bereits eingelöst',
            nonApplicable:
                'Der Gutschein ist nicht (mehr) aktiv oder kann für deinen aktuellen Warenkorb nicht angewendet werden!',
        },
        emptyCart: 'Dein Warenkorb ist noch leer!',
        emptyCartMessage: 'Jetzt einkaufen und Produkte hinzufügen.',
        emptyCartButton: 'Zurück zum Shop',
        emptyCartButtonMessage: 'Einkauf fortsetzen',
        voucherModal: {
            headline: 'Der Gutschein wurde erfolgreich aktiviert!',
            headlineGiftLineItem: 'Dein Gratisgeschenk wurde aktiviert!',
            text: '{totalSavings} Ersparnis ab {minValue} Mindestbestellwert',
            continue: 'JETZT SHOPPEN',
        },
        wkoProductTitle: 'Dein Gratisgeschenk',
        wkoModalHeadline: 'Passendes Zubehör gleich mitbestellen und Geschenk sichern',
        wkoDeclineOffer: 'Nein danke',
        wkoAddToCartSuccess: 'Gute Wahl!\nDer Artikel wurde hinzugefügt.',
        restoreCartError: 'Dein Warenkorb konnte leider nicht wiederhergestellt werden.',
        clubSavings: {
            clubSavingsText: 'Mit Kundenclub {savings} sparen!',
            clubSavingsLink: 'Klicke {clubSavingsUnderline} für weitere Informationen.',
            clubSavingsUnderline: 'hier',
        },
        clubExtensionCta: 'verlängern',
        freeShippingBanner: {
            freeShipping: 'versandkostenfreien Lieferung',
            headline: 'Nur noch {onlyLeftPrice} bis zur {freeShipping} ab {fromPrice}',
            continue: 'Weiter ',
            shopping: 'einkaufen',
            order: 'Diese Bestellung wird ',
            freeDelivery: 'versandkostenfrei geliefert!',
        },
    },
    category: {
        showMore: 'Mehr anzeigen',
        breadcrumbHomeAriaLabel: 'Startseite',
    },
    voucher: {
        codes: {
            notActive: 'Gutschein ist nicht aktiv.',
            notValid: 'Gutschein ist nicht mehr gültig.',
            doesNotMatchCart: 'Gutschein erfüllt die Bedingungen nicht.',
            maxApplicationReached: 'Gutschein wurde bereits eingelöst.',
            applicationStoppedByPreviousDiscount:
                'Gutschein kann aufgrund eines anderen Gutscheins nicht eingelöst werden.',
            matchesCart: 'Gutschein ist gültig',
        },
        modal: {
            dontShowLegalText: 'Gutscheinkonditionen ausblenden',
            displayLegalText: 'Gutscheinkonditionen anzeigen',
            savings: '{amount} Ersparnis',
            headline: 'Welchen Gutschein möchtest du behalten?',
            minimalOrderAmount: ' ab {amount} Mindestbestellwert',
        },
        priceGiftLineItem: 'Gratisgeschenk: -{price}',
    },
    checkout: {
        minicartEdit: 'bearbeiten',
        dividerText: 'Oder',
        allowRecurrence: {
            checkbox: 'Wiederkehrende Zahlungen zulassen',
            error: 'Du musst wiederkehrenden Zahlungen zustimmen.',
        },
        catalogBillingAddressHeadline: 'Rechnungsadresse',
        editBillingAddress: 'Rechnungsadresse bearbeiten',
        billingAddressHeadline: '1. Rechnungsadresse',
        guestInfo:
            'Deine Daten werden nur für diese Bestellung erhoben und müssen ' +
            'für weitere Bestellungen erneut angegeben werden. ' +
            'Um von den Vorteilen eines Kundenkontos zu profitieren, hast du ' +
            'nach Abschluss der Bestellung die Möglichkeit, ein kostenloses Kundenkonto zu erstellen.',
        paymentHeadline: 'Bitte Zahlungsart auswählen:',
        summaryHeadline: 'Zusammenfassung',
        nextStep: 'Weiter',
        checkOrder: 'Zur Bestellüberprüfung',
        nextStepPayment: 'Weiter zu den Zahlungsarten',
        lastStep: 'Schritt zurück',
        buyNow: 'Jetzt kaufen',
        cart: 'Warenkorb',
        lineItem: {
            quantity: 'Menge',
        },
        freeDelivery: 'Diese Bestellung wird GRATIS versendet!',
        enterAddress:
            'Bitte gib zunächst deine Adressdaten ein und bestätige ' +
            'diese anschließend mit Klick auf "Weiter zur Zahlung". ' +
            '\n\nIm nächsten Schritt werden dir die verfügbaren Zahlungsarten angezeigt.',
        addresses: {
            headline: 'Daten & Versand',
            customerLogin: 'Bereits registriert?<br>Zum Kundenkonto Login',
            guestOrder: 'Als Gast bestellen /<br>Kundenkonto anlegen',
            register: 'Kundenkonto anlegen',
        },
        payment: {
            paymentFee: 'Transaktionsspesen: {price}',
            paymentFeeForNachnahme: 'Transaktionsspesen: {price}',
            noPaymentFee: 'Keine Transaktionsspesen',
            noPaymentFeeForNachnahme: 'Keine Transaktionsspesen',
            networkError:
                'Leider können wir dir derzeit keinen Kauf auf Rechnung anbieten. ' +
                'Bitte wähle eine andere Zahlungsart aus.',
            tryOtherNetworkError:
                'Leider können wir dir derzeit keinen Kauf auf Rechnung anbieten. ' +
                'Bitte wähle eine andere Zahlungsart aus.',
            installmentNetworkError:
                'Für diese Bestellung können wir leider keine Ratenzahlung anbieten. ' +
                'Bitte wähle eine alternative Zahlungsoption aus, um deine Bestellung abzuschließen.',
            invoiceNetworkError:
                'Für diese Bestellung können wir leider keinen Kauf auf Rechnung anbieten. ' +
                'Bitte wähle eine alternative Zahlungsoption aus, um deine Bestellung abzuschließen.',
            invoiceCheckDateOfBirth:
                'Deine übermittelten Daten konnten nicht erfolgreich überprüft werden. ' +
                'Bitte kontrolliere deine Eingaben zum Geburtsdatum und achte auf ' +
                'Korrektheit und Vollständigkeit.',
            invoiceAddressError:
                'Bitte beachte, dass Liefer- und Rechnungsadresse beim Rechnungskauf ' +
                'für die Identifizierung übereinstimmen müssen. ' +
                'Deine aktuellen Adresseingaben erfüllen diese Anforderung nicht, ' +
                'weshalb ein Kauf auf Rechnung leider nicht möglich ist. ' +
                'Bitte wähle eine alternative Zahlungsoption aus, um deine Bestellung abzuschließen.',
            genericError:
                'Deine Zahlung ist fehlgeschlagen. Bitte überprüfe deine Rechnungs- und Zahlungsdaten, ' +
                'bevor du es erneut versuchst, oder verwende eine andere Zahlungsmethode.',
            unavailable: 'Zahlungsart temporär nicht verfügbar',
            invoice: 'Kauf auf Rechnung',
            invoiceoffline: 'Kauf auf Rechnung',
            collectminvoice: 'Kauf auf Rechnung',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'Sofort-Überweisung',
            nachnahme: 'Nachnahme',
            installment: 'Ratenzahlung',
            installmentActivateCTA: 'Jetzt prüfen',
            installmentCheckbox:
                'Du stimmst der Ratenzahlungsvereinbarung zu und kannst den ' +
                'Ratenkredit-Vertragsentwurf {linkStart}hier{linkEnd} herunterladen.',
            afterpayinvoice: 'AfterPay Riverty',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Hinweis',
            unavailableShippingCountry:
                'Die Bestellung kann nicht an den von dir ausgewählten Lieferort versendet werden.',
            descriptions: {
                postFinanceCard:
                    'Bezahle einfach und bequem zu Lasten deines Postkontos. Nach der ' +
                    'Kaufbestätigung gibst du in der PostFinance Bezahlmaske die Karten- und ID-Nummer ' +
                    'deiner PostFinance Card ein und bestätigst die Zahlung.',
                visa: 'Sichere Zahlung mittels Visa Secure.',
                mastercard: 'Sichere Zahlung mittels Mastercard Identity Check.',
                payPal:
                    'Einfache Zahlung über dein PayPal-Konto. Am Ende deines Bestellvorgangs wirst du direkt zu ' +
                    'PayPal weitergeleitet. Erst nach der Zahlungsabwicklung dort ist deine Bestellung abgeschlossen. ',
                payPalPayLater:
                    '(Falls gewünscht kannst du optional nach dem Login bei PayPal zusätzlich die Option "Zahlen nach 30 Tagen" auswählen.)',
                sofortueberweisung:
                    'Zahlung mit deinen Online-Banking-Daten. Nach der Kaufbestätigung wirst du ' +
                    'direkt zum sicheren Zahlungsportal von Sofort Klarna weitergeleitet.',
                invoice:
                    'Kaufe unkompliziert per Rechnung. Die Rechnung erhältst du gemeinsam mit der ' +
                    'Lieferung. Bitte beachte, dass Liefer- und Rechnungsadresse beim Rechnungskauf für ' +
                    'die Identifizierung übereinstimmen müssen. Diese Zahlungsart setzt ein Mindestalter von ' +
                    '18 Jahren voraus und schließt eine Bonitätsprüfung mit ein. Achte auf die korrekte Eingabe ' +
                    'deines Geburtsdatums.',
                invoiceOffline:
                    'Kaufe unkompliziert per Rechnung. Die Rechnung erhältst du gemeinsam mit der Lieferung.',
                installment:
                    'Bezahle sicher und flexibel in Raten. Diese Zahlungsart setzt ein ' +
                    'Mindestalter von 18 Jahren voraus und schließt eine Bonitätsprüfung mit ein. Achte auf ' +
                    'die korrekte Eingabe der Adressdaten und deines Geburtsdatums.',
                ideal:
                    'Zahlung mit deinen Online-Banking-Daten. Nach der Kaufbestätigung wirst du direkt ' +
                    'zum Online-Portal deiner niederländischen Bank weitergeleitet, wo du dich mit deinen ' +
                    'Zugangsdaten für dein Online-Banking anmelden kannst, um die Zahlung durchzuführen.',
                afterPay:
                    'In wenigen Schritten per Rechnung bezahlen. Diese Zahlungsart wird von AfterPay ' +
                    'durchgeführt und setzt ein Mindestalter von 18 Jahren voraus. Die korrekte Eingabe deines ' +
                    'Geburtsdatums und deiner Telefonnummer ist verpflichtend, damit AfterPay eine Identitäts- und ' +
                    'Bonitätsprüfung durchführen kann.',
                bancontact:
                    'Du benötigst eine Bancontact-Karte bei einer teilnehmenden Bank. Nach der ' +
                    'Kaufbestätigung wirst du direkt zum Online-Portal Bancontact weitergeleitet, wo du entweder ' +
                    'die Bancontact-App nutzt oder deine Bancontact-Kartendetails manuell eingibst, um die ' +
                    'Zahlung durchzuführen.',
            },
        },
        shippingAddressHeadline: 'Lieferadresse',
        summary: {
            headline: 'Zusammenfassung',
            usps: {
                moneyBack: 'Geld-zurück-Garantie',
                freeDelivery: 'kostenloser Versand',
                refund: '14 Tage Rückgaberecht',
                freeReturn: 'kostenloser Rückversand',
                securePayment: 'Sicher bezahlen',
            },
            subtotal: 'Zwischensumme',
            deliveryCost: 'Versand',
            dropShippingCost: 'Liefergebühr (Spedition)',
            bulkyDeliveryCost: 'Liefergebühr (Sperrgut)',
            transactionFee: 'Transaktionsspesen',
            transactionFeeCashOnDelivery: 'Transaktionsspesen',
            total: 'Gesamtbetrag',
            taxIncluded: 'inkl. MwSt.',
            totalSavings: 'Gesamte Ersparnis',
            privacy: 'Wir verwenden deine Daten ausschließlich gemäß unserer {termsDetails}.',
            legal: 'Mit Klick auf "Jetzt Kaufen" erklärst du dich mit den {termsDetails} von {shopName} einverstanden.',
            legalDetails: {
                privacyText: 'Datenschutzerklärung',
                privacyLink: '/datenschutz',
                termsText: 'Allgemeinen Geschäftsbedingungen',
                termsLink: '/agb',
            },
            permissionEmailPhonePost:
                'Ich stimme zu, dass meine Daten - wie in der ' +
                '<link>Datenschutzerklärung</link> beschrieben - zur Information von neuen Produkten ' +
                'und Dienstleistungen per E-Mail, Telefon oder Post verarbeitet werden. Diese Zustimmung ' +
                'kann ich jederzeit widerrufen.',
            permissionEmailPhonePostDetails: {
                link: '/datenschutz',
            },
            buyNow: 'Jetzt kaufen',
            deliveryTime: 'Lieferbar in {time} Werktagen',
            newsletterSubscription:
                'Ich möchte per E-Mail kostenlos den {shopName} Newsletter mit Angeboten, Vorteilen und ' +
                'Gutscheinen erhalten. Die Abmeldung ist jederzeit durch Link in den Newsletter E-Mails möglich.',
            comment: 'Nachricht an den Zusteller',
        },
        orderNotSavedYet: 'Deine Bestellung wurde noch nicht gespeichert. Möchtest du die Seite wirklich verlassen?',
        resetPassword: {
            title: 'Hinweis',
            textBold: 'Du bist bereits unser Kunde und hattest schon einen Zugang zu unserem alten Onlineshop?',
            textNormal: 'Jetzt dein {link} und dein bestehendes Kundenkonto weiterhin nutzen.',
            textCta: 'bisheriges Passwort zurücksetzen',
            resetButton: 'Passwort zurücksetzen',
            closeButton: 'Schließen',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Achtung',
                description: 'Bist du sicher, dass du zum Warenkorb zurückkehren möchtest?',
                dismiss: 'Im Kassenvorgang bleiben',
                submit: 'Warenkorb bearbeiten',
            },
            summary: {
                amount: 'Menge: {amount}',
                billingAddress: 'Rechnungsadresse',
                billingAndDelivery: 'Rechnung & Lieferung',
                change: 'ändern',
                lineItemTotalPrice: 'Gesamtpreis: {price}',
                paymentType: 'Zahlungsart',
                shippingAddress: 'Lieferadresse',
                title: 'Zusammenfassung',
                yourOrder: 'Deine Bestellung',
            },
            headlines: {
                headline: 'Sicher bestellen in 3 Schritten',
                address: 'Deine Daten,\nLieferadresse',
                payment: 'Zahlungsart',
                summary: 'Prüfen &\nbestellen',
            },
            nextButton: 'Weiter',
            checkDataButton: 'Angaben prüfen',
        },
        customerClubExtensionModal: {
            title: 'Achtung',
            text:
                'Du bist bereits Mitglied im Kundenclub. Die Kundenkarte wurde aus deinem Warenkorb entfernt. ' +
                'Möchtest du stattdessen eine Verlängerung deiner bestehenden Mitgliedschaft kaufen?',
            addToCart: 'Verlängerung kaufen',
            closeButton: 'Schließen',
        },
        subscriptionInfo:
            'Für den Kauf eines Abos benötigst du ein Kundenkonto. Log dich entweder mit deinem bestehenden ' +
            'Kundenkonto ein oder erstell einfach ein neues Kundenkonto.',
    },
    totalPrice: {
        taxIncluded: 'inkl. MwSt.',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Rechnungsadresse*',
        requiredField: 'Alle mit * markierten Felder sind Pflichtfelder.',
        emailLabel: 'E-Mail-Adresse',
        emailPlaceholder: 'E-Mail-Adresse',
        emailErrorText: 'Bitte gib eine gültige E-Mail-Adresse ein.',
        salutationLabel: 'Anrede*',
        salutationPlaceholder: 'Anrede wählen',
        salutations: {
            ms: 'Frau',
            mr: 'Herr',
            none: 'Keine Angabe',
        },
        customerTypeLabel: 'Kundentyp',
        customerType: {
            private: 'Privatperson',
            corporate: 'Firma',
        },
        contactLabel: 'Kontakt*',
        contactPerson: 'Kontaktperson',
        contactInfo: 'Kontaktinformationen',
        firstnamePlaceholder: 'Vorname',
        firstnameErrorText: 'Bitte gib einen gültigen Vornamen ein.',
        lastnamePlaceholder: 'Nachname',
        lastnameErrorText: 'Bitte gib einen gültigen Nachnamen ein.',
        nameLengthErrorText: 'Maximal 200 Zeichen erlaubt.',
        phonePlaceholder: 'Telefonnummer',
        phoneErrorText: 'Bitte gib eine Telefonnummer im Format {examplePhoneNumber} ein.',
        company: 'Firma',
        companyPlaceholder: 'Firma',
        companyErrorText: 'Bitte gib einen gültigen Firmennamen ein.',
        vatIdPlaceholder: 'Steuernummer',
        vatIdErrorText: 'Bitte gib eine gültige Steuernummer ein.',
        address: 'Adresse',
        streetPlaceholder: 'Straße',
        streetErrorText: 'Bitte gib eine gültige Straße ein.',
        streetLengthErrorText: 'Maximal 100 Zeichen erlaubt.',
        streetLengthErrorTextCZ: 'Maximal 40 Zeichen erlaubt.',
        streetNumberPlaceholder: 'Hausnummer',
        streetNumberErrorText: 'Bitte gib eine gültige Hausnummer ein.',
        zipCodePlaceholder: 'PLZ',
        zipCodeErrorText: 'Bitte gib eine gültige PLZ ein.',
        cityPlaceholder: 'Ort',
        cityErrorText: 'Bitte gib einen gültigen Ort ein.',
        country: 'Land',
        countryLabel: 'Land',
        countryPlaceholder: 'Land wählen',
        countyLabel: 'Bezirk',
        countyPlaceholder: 'Bezirk wählen',
        packingStationPlaceholder: 'Lieferung an eine Packstation:*',
        packingStation: {
            yes: 'Ja',
            no: 'Nein',
        },
        shippingAddressHeadline: 'Lieferadresse:*',
        shippingAddressPlaceholder: 'Lieferadresse gleich wie Rechnungsadresse?*',
        shippingAddress: {
            yes: 'Ja',
            no: 'Nein',
        },
        dayPlaceholder: 'Tag',
        monthPlaceholder: 'Monat',
        yearPlaceholder: 'Jahr',
        birthdateTitle: 'Geburtsdatum (optional)',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'Bitte gib ein gültiges Datum ein.',
        birthdateTooYoungErrorText: 'Du musst mindestens 18 Jahre alt sein.',
        birthdateDayErrorText: '*Ungültig',
        birthdateMonthErrorText: '*Ungültig',
        birthdateYearErrorText: '*Ungültig',
        birthdayTitle: 'Geburtsdatum',
        birthDayError: 'Das Alter darf nicht 100 Jahre überschreiten.',
        loqatePlaceholder: 'Bitte gib die Adresse ein',
        loqateManualAddress: 'Adresse manuell eingeben',
        loqateManualAddressHint: 'oder komfortabel nach Adresse suchen:',
        loqateSearchAddress: 'alternativ nach Adresse suchen?',
        noResults: 'Leider gab es keine Ergebnisse',
    },
    checkoutSuccess: {
        headline: 'Vielen Dank für deine Bestellung',
        headlineError: 'Es ist ein Fehler aufgetreten',
        textFirst:
            'Wir haben deine Bestellung erhalten und senden dir ebenfalls eine Bestätigung an ' +
            '<link>{email}</link>.',
        textSecond: 'Sobald deine bestellte Ware unser Versandlager verlässt, informieren wir dich erneut per E-Mail.',
        textSecondPending:
            'Deine Bestellung wird bearbeitet. ' +
            'Wir benachrichtigen dich per E-Mail, sobald deine Bestellung erstellt wurde.',
        textError: 'Kontaktiere bitte den Kundenservice zum Status deiner Bestellung.',
        noEmailPlaceholder: 'Deine E-Mail-Adresse',
        newsletter: {
            headline: 'Newsletter',
            text:
                'Ich möchte mittels {strongStart}Newsletter{strongEnd} über aktuelle Angebote von ' +
                '{shopName} kostenlos informiert werden.',
            feedbackText:
                'Newsletter Anmeldung erfolgreich! Du wirst in Kürze eine E-Mail mit einem Link zur ' +
                'Bestätigung deiner E-Mail-Adresse erhalten.',
            error: 'Die Newsletter Anmeldung ist derzeit nicht möglich.',
        },
        catalog: {
            headline: 'Katalog',
            text:
                'Ich möchte den {shopName} {strongStart}Katalog{strongEnd} kostenlos und bequem per ' +
                'Post nach Hause geliefert bekommen.',
            feedbackText: 'Der Katalog wird demnächst an deine Adresse geschickt.',
            error: 'Die Katalog Bestellung ist derzeit nicht möglich.',
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'Land ändern',
        AT: 'Österreich',
        DE: 'Deutschland',
        CH: 'Schweiz',
        LI: 'Liechtenstein',
        HU: 'Ungarn',
        IT: 'Italien',
        BE: 'Belgien',
        NL: 'Niederlande',
        BG: 'Bulgarien',
        HR: 'Kroatien',
        CY: 'Zypern',
        CZ: 'Tschechien',
        DK: 'Dänemark',
        EE: 'Estland',
        FI: 'Finnland',
        FR: 'Frankreich',
        GR: 'Griechenland',
        IE: 'Irland',
        LV: 'Lettland',
        LT: 'Litauen',
        LU: 'Luxemburg',
        MK: 'Mazedonien',
        MT: 'Malta',
        PL: 'Polen',
        PT: 'Portugal',
        RO: 'Rumänien',
        SM: 'San Marino',
        SK: 'Slowakei',
        SI: 'Slowenien',
        ES: 'Spanien',
        SE: 'Schweden',
    },
    socialMedia: {
        facebook: 'Auf Facebook teilen',
        twitter: 'Auf Twitter teilen',
        whatsapp: 'Via Whatsapp teilen',
        native: 'Teilen',
    },
    productListing: {
        noProducts: 'Keine Produkte gefunden',
        products: '{count} {count, plural, one {Produkt} other {Produkte}}',
        loadMore: 'Mehr laden',
        from: 'von',
        articles: 'Artikel',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - Seite {count}}}',
        sort: {
            bestseller: 'Bestseller',
            price: {
                asc: 'Preis aufsteigend',
                desc: 'Preis absteigend',
            },
            name: {
                asc: 'Name A-Z',
                desc: 'Name Z-A',
            },
        },
    },
    pagination: {
        ariaPage: 'Seite {pageNumber}',
        next: 'Weiter',
        previous: 'Zurück',
    },
    menu: {
        logout: 'Abmelden',
        logoutHeader: 'Achtung',
        logoutText: 'Möchtest du dich wirklich abmelden?',
        logoutButtonText: 'Abmelden',
    },
    login: {
        password: 'Passwort',
        submitButtonLabel: 'Sicher einloggen',
        emailPlaceholder: 'E-Mail-Adresse',
        passwordLabel: 'Passwort eingeben',
        usernameEmptyError: 'Benutzername darf nicht leer sein',
        usernameEmailError: 'Bitte gib eine gültige E-Mail-Adresse ein.',
        userLoginError: 'Benutzername oder Passwort sind falsch',
        passwordForgotten: 'Passwort vergessen?',
        loginSuccess: 'Du bist eingeloggt!',
        passwordEmptyError: 'Passwort darf nicht leer sein',
        existingUser: 'Ich bin bereits Kunde, zur Anmeldung',
        resetPassword: 'Passwort zurücksetzen',
        resetEmailSent: 'Wir haben dir ein E-Mail an {email} gesendet.',
        resetErrorQuestions: 'Keine Nachricht erhalten?',
        resetErrorAnswerOne: 'Bitte überprüfe den Spam-Ordner von deinem E-Mail Postfach.',
        resetErrorAnswerTwo: 'Bitte überprüfe deine Angaben nocheinmal.',
        resetErrorAnswerThree: 'Bitte warte ca. 15 Minuten bevor du es erneut versuchst.',
        newPassword: 'Neues Passwort',
        passwordNotMatchingReq: 'Passwort entspricht nicht den Anforderungen',
        passwordsNotIdentical: 'Neue Passwörter stimmen nicht überein',
        repeatPassword: 'Passwort bestätigen',
        changePassword: 'Passwort ändern',
        passwordChanged: 'Das Passwort wurde erfolgreich geändert',
        emailConfirmed: 'E-Mail bestätigt! Du kannst dich jetzt anmelden.',
        processToken: 'Beim Verarbeiten des Tokens ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
        generalError: 'Es gab einen Fehler. Bitte versuche es später erneut.',
        authorizeStoreTitle: 'Kundenkonto verknüpfen',
        authorizeStoreText: 'Möchtest du dein bestehendes Kundenkonto für {shopName} verwenden?',
        yes: 'Ja',
        no: 'Nein',
        emailUnconfirmed: 'Bitte bestätige zuerst deine E-Mail-Adresse.',
    },
    orders: {
        order: 'Bestellung',
        total: 'Summe',
        details: 'Details ansehen',
        back: 'Zurück',
        shippingAddress: 'Lieferadresse',
        paymentMethod: 'Zahlart',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Menge: {quantity}',
        reorder: 'Nochmals kaufen',
        reOrderSuccess: 'Artikel erfolgreich hinzugefügt',
        reOrderFail: 'Artikel konnte nicht zum Warenkorb hinzugefügt werden',
        date: 'Datum',
        progress: '{items} von {total} Bestellungen',
    },
    customer: {
        mr: 'Herr',
        mrs: 'Frau',
        customerNumber: 'Kundennummer',
        billingAddress: 'Rechnungsadresse',
        email: 'E-Mail-Adresse',
        phone: 'Telefon',
        password: 'Passwort',
        passwordText: `Das Passwort wird aus Sicherheitsgründen\nnicht angezeigt.`,
        passwordLinkText: 'Passwort vergessen?',
        tokenNotReceived: 'Deine E-Mail existiert nicht in unserer Datenbank',
        emailInvalid: 'Ungültige E-Mail-Adresse',
        dataUpdated: 'Daten aktualisiert',
        wrongPassword: 'Passwort konnte nicht geändert werden',
        couldNotResetPassword: 'Zurücksetzen des Passworts nicht möglich.',
        customerClub: {
            cardNumber: 'Kartennummer: {number}',
            validFrom: 'Mitglied seit: {date}',
            validThru: 'Gültig bis: {date}',
            expiryWarning:
                'Achtung: Deine Karte verliert in {days} {days, plural, one {Tag} other {Tagen}} ihre Gültigkeit.',
            cardExpired: 'Achtung: Deine Karte ist abgelaufen.',
            offerCardRenewal: 'Neue Karte bestellen',
        },
        customerCardLink: {
            headline: 'Verknüpfe deinen Onlineaccount mit deiner Clubkarte',
            subHeadline: 'Nutze die Vorteile des Kundenclubs auch bei deinen Einkäufen in unserem Onlineshop.',
            cardNumber: 'Kartennummer',
            clubEmail: 'Kundenclub E-Mail-Adresse',
            cta: 'Verknüpfen',
            success: 'Deine Kundenclubkarte wurde erfolgreich mit deinem Konto verknüpft.',
            error: {
                mykeneNotFound: 'Es konnte keine Kundenclubkarte mit der angegebenen Nummer gefunden werden.',
                mykeneInvalidCombination: 'Die E-Mail-Adresse stimmt nicht mit der Kundenclubkarte überein.',
                mykeneError: 'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
                cardExistsForStore: 'Deine Kundenclubkarte wurde bereits einem Konto hinzugefügt.',
                unknownError: 'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
            },
        },
    },
    register: {
        passwordEmptyError: 'Passwort darf nicht leer sein',
        passwordMinError: 'Passwort muss mindestens 8 Zeichen lang sein',
        passwordNotValidError: 'Mindestens 1x Ziffer, 1x Großbuchstabe, 1x Kleinbuchstabe',
        passwordConfirmationError: 'Passwörter stimmen nicht überein',
        passwordLabel: 'Passwort eingeben',
        passwordConfirmationLabel: 'Passwort wiederholen',
        passwordCriteriaHeader: 'Dein Passwort muss ...',
        passwordCriteria: {
            numberOfCharacters: '✔ mindestens 8 Zeichen lang sein',
            hasNumber: '✔ mindestens 1 Ziffer enthalten',
            hasUppercaseLetter: '✔ mindestens 1 Großbuchstaben enthalten',
            hasLowercaseLetter: '✔ mindestens 1 Kleinbuchstaben enthalten',
        },
        acceptGDPRError: 'Du musst der Datenschutzerklärung zustimmen.',
        nextStepPayment: 'Weiter zur Zahlung',
        error: 'Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        successMessage: 'Registrierung erfolgreich. Bitte bestätige zuerst deine E-Mail-Adresse.',
        mainError: 'Bitte korrigiere den/die Fehler!',
        change: 'ÄNDERN',
        accountVerificationTitle: 'Kontoverifizierung',
        accountVerificationText:
            'Du erhältst in Kürze ein E-Mail mit einem Link zur ' +
            'Aktivierung deines Kundenkontos. Bitte beachte, dass dieser Link nur 24 Stunden gültig ist. ' +
            'Nicht verifizierte Kundenkonten werden nach 24 Stunden automatisch gelöscht.',
        accountVerificationCTA: 'OK',
        existingAccountModal: {
            text:
                'Diese E-Mail-Adresse wird bereits verwendet. Wenn du ein Konto für einen der folgenden' +
                ' Shops hast, melde dich bitte mit den bekannten Zugangsdaten an.',
            shops: `<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>`,
            accept: 'Zum Login',
            close: 'Schließen',
        },
        newsletterSubscription: 'Ich möchte über aktuelle Angebote von {shopName} informiert werden.',
        existingAccountToast: 'Diese E-Mail-Adresse wird bereits verwendet.',
    },
    countrySwitcher: {
        headline: 'Sprache und Versandoptionen anpassen',
        country: 'Lieferung nach',
        language: 'Sprache',
        button: 'Speichern',
        modal: {
            headline: 'Achtung',
            description:
                'Du änderst deine Region gerade zu {newSelectedCountry}. Damit du etwas bestellen kannst, ' +
                'muss deine Versandadresse in dieser Region liegen. Wenn du nicht in der Währung der Region ' +
                'bezahlst, berechnet dir deine Bank eventuell eine Umtauschgebühr. Alle Artikel in deinem ' +
                'Einkaufswagen werden entfernt.',
            submitText: 'Weiter',
            closeText: 'Abbrechen',
        },
    },
    personalInfo: {
        newEmail: 'Neue E-Mail-Adresse',
        newEmailConfirm: 'Neue E-Mail-Adresse bestätigen',
        passwordConfirm: 'Passwort bestätigen',
        currentPassword: 'Aktuelles Passwort',
        newPassword: 'Neues Passwort',
        newPasswordConfirm: 'Neues Passwort bestätigen',
        actualEmail: 'Aktuelle E-Mail-Adresse',
    },
    newsletter: {
        inputPlaceholder: 'E-Mail-Adresse',
        success: 'Du erhältst in Kürze ein E-Mail mit einem Bestätigungslink.',
        invalidEmail: 'Bitte gib eine gültige E-Mail-Adresse ein.',
        error: 'Leider ist bei der Anmeldung zum Newsletter ein Problem aufgetreten.',
        emailEmptyError: 'Bitte gib eine E-Mail-Adresse ein',
        registered: 'Du hast den Newsletter abonniert.',
        notRegistered: 'Du hast den Newsletter nicht abonniert.',
        subscribe: 'Abonnieren',
        unsubscribe: 'Abbestellen',
        alreadySubscribed: 'E-Mail-Adresse ist bereits für den Newsletter angemeldet',
        successAccount: 'Du hast dich erfolgreich vom Newsletter abgemeldet.',
        alreadyRegisteredQuestion: 'Bereits registriert?',
        unsubscribeHere: ' Du kannst dich hier abmelden',
        successUnsubscribe: 'Du hast dich erfolgreich abgemeldet',
        unsubscribeError: 'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
    },
    cartChangeModal: {
        headline: 'Achtung',
        description: 'Der Warenkorb wurde aktualisiert, bitte wähle die Zahlungsmethode erneut aus.',
        submitText: 'OK',
    },
    clubCustomer: {
        priceMessage: 'Stammkundenpreis: {price}',
        modal: {
            bannerHeadline: 'WERDE UNSER STAMMKUNDE!',
            bannerSubHeadline: 'PERSONALISIERTE RABATTE UND GUTSCHEINE ERWARTEN DICH!',
            headline: 'WARUM LOHNT SICH EINE MITGLIEDSCHAFT JETZT WIRKLICH?',
            text:
                'Mit der jährlichen Loyalty-Mitgliedschaft kannst du bares Geld sparen. ' +
                'Klicke auf die Schaltfläche unten und bestelle deine eigene Karte:',
            button: 'KARTE BESTELLEN',
        },
    },
    infoBanner: {
        closeButton: 'Schließen',
    },
    slider: {
        prevButton: 'Zurück',
        nextButton: 'Weiter',
        activePagination: 'aktiver Slide',
        inactivePagination: 'inaktiver Slide',
    },
    specialDeal: {
        days: '{count, plural, one {Tag} other {Tage}}',
        hours: 'Std.',
        minutes: 'Min.',
        seconds: 'Sek.',
        specialDealOver: 'Diese Aktion ist leider beendet.',
    },
    userManuals: {
        headline: 'Handbücher',
        search: 'Suche nach Produkt, Artikelnummer oder Code',
        resetSearch: 'Suche zurücksetzen',
        table: {
            sku: 'Artikelnummer',
            code: 'Code',
            productTitle: 'Produkt',
            type: 'Typ',
            types: {
                instruction_manual: 'Bedienungsanleitung',
                recipe_booklet: 'Rezeptheft',
                training_plan: 'Trainingsplan',
                nutrition_plan: 'Ernährungsplan',
            },
        },
        noResults: 'Keine Handbücher gefunden.',
    },
    price: {
        from: 'ab\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'statt\u00a0{price}',
        uvp: 'UVP\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'Die Änderung wurde erfolgreich durchgeführt.',
        error: 'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
    },
    subscription: {
        amount: 'Menge:',
        deliveryInterval: 'Lieferintervall:',
        nextDelivery: 'Nächste Lieferung:',
        ordered: 'Bestellt am:',
        status: 'Status:',
        cancelSubscription: 'Abo beenden',
        state: {
            active: 'Aktiv',
            paused: 'Pausiert',
            cancelled: 'Gekündigt',
            problems: 'Probleme',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Tag} other {Tage}}',
            month: '{amount} {amount, plural, one {Monat} other {Monate}}',
            year: '{amount} {amount, plural, one {Jahr} other {Jahre}}',
        },
    },
    routerErrorBoundary: {
        title: 'Keine Verbindung zum Server möglich',
        infoText: 'Bitte überprüfe deine Internetverbindung oder versuche es später noch einmal.',
        buttonText: 'Seite neuladen',
        stacktraceTitle: 'Details',
    },
} satisfies IntlMessages;
