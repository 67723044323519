import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCategories } from '../../queries/categories/useCategories';
import { queries } from '..';
import { useShopContext } from '../../hooks/useShopContext';
import { PRODUCT_GRID_PAGE_LIMIT } from '../../constants/api';
import { useCategoryApiClient } from '../../api/hooks/useCategoryApiClient';

export enum ProductSortingOption {
    Bestseller = 'bestseller',
    PriceASC = 'price.asc',
    PriceDESC = 'price.desc',
    NameASC = 'name.asc',
    NameDESC = 'name.desc',
}

type Props = {
    categoryId: string;
    page?: number;
    limit?: number;
    withSpecialDeal?: boolean;
    sort?: ProductSortingOption;
};

export const useCategoryProducts = ({
    categoryId,
    page = 1,
    limit = PRODUCT_GRID_PAGE_LIMIT,
    withSpecialDeal = false,
    sort,
}: Props) => {
    const apiClient = useCategoryApiClient();
    const { data: categories } = useCategories();

    const { country, currency, locale } = useShopContext();

    const offset = (page - 1) * limit;

    const queryLimit = withSpecialDeal && page === 1 ? limit - 1 : limit;
    const queryOffset = withSpecialDeal && offset > 1 ? offset - 1 : offset;

    const queryFn = () => {
        return apiClient.getCategoryProducts(categoryId, country, currency, locale, queryLimit, queryOffset, sort);
    };

    return useQuery({
        ...queries.categories.products(categoryId, country, currency, locale, queryLimit, queryOffset, sort ?? ''),
        queryFn,
        placeholderData: keepPreviousData,
        enabled: Boolean(categories) && Boolean(categoryId),
    });
};
